import React, { useState, useEffect, useMemo } from "react";
import CustomSideBar from "../../components/layouts/sidebar";
import CustomNavbar from "../../components/layouts/navbar";
import { Button, Typography } from "@material-tailwind/react";
import {
  ArrowPathIcon,
  MagnifyingGlassIcon,
  PlusIcon,
} from "@heroicons/react/24/outline";
import MaterialReactTable from "material-react-table";
import BaseService from "../../utils/helpers/baseServices";
import { gloabal_variables } from "../../utils/helpers/globalVariables";
import DatePicker from "../../components/general/DatePicker";
import { endOfDay, startOfDay } from "date-fns";
import moment from "moment";
import BranchFilter from "../../components/general/branchFilter";
import AddStaffModal from "./addStaffModal";
import RoleFilter from "../../components/general/roleFilter";
import TotalStaffCard from "../../pages/overview-page/totalStaffCard";

const StaffPage = () => {
  // const navigate = useNavigate();
  //const { id } = useParams();
  const [isFetching, setIsFetching] = useState(false);
  const [actualSearchValue, setActualSearchValue] = useState("");
  const [companiesData, setCompaniesData] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageIndex: 0,
  });
  const [total, setTotal] = useState(0);
  const [defaultDates, setDefaultDates] = useState(null);
  const [reset, setReset] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [reFetch, setReFetch] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");

  //open add staff
  const [openAddModal, setOpenAddModal] = React.useState(false);
  const handleOpenAddModal = () => setOpenAddModal(true);
  const handleCloseAddModal = () => setOpenAddModal(false);

  const handleReFetch = () => {
    setReFetch(!reFetch);
  };

  //onload
  useEffect(() => {
    const fetchTransactions = async () => {
      setIsFetching(true);
      try {
        let offset = pagination.pageIndex * pagination.pageSize;

        const defaultFilters = [];

        if (defaultDates) {
          defaultFilters.push({
            f: "created_at",
            o: "between",
            p: [defaultDates[0].toISOString(), defaultDates[1].toISOString()],
          });
        }

        if (actualSearchValue) {
          defaultFilters.push({
            f: "user.first_name",
            o: "contains",
            p: [actualSearchValue],
          });
        }

        if (selectedBranch) {
          defaultFilters.push({
            f: "branch.code",
            o: "contains",
            p: [selectedBranch],
          });
        }

        if (selectedRole) {
          defaultFilters.push({
            f: "role_id",
            o: "=",
            p: [selectedRole],
          });
        }

        const response = await BaseService.fuel_backoffice_v1_get_api(
          `${gloabal_variables.get_all_staff}?sorting=updated_at:desc&limit=${
            pagination.pageSize
          }&offset=${offset}&filters=${JSON.stringify(defaultFilters)}`
        );
        // console.log(response?.data);

        setCompaniesData(response?.data?.payload?.items);
        setTotal(response?.data?.payload?.total);
        setIsFetching(false);
      } catch (error) {
        // console.log(error);
        setIsFetching(false);
      }
    };

    fetchTransactions();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    defaultDates,
    actualSearchValue,
    reset,
    selectedBranch,
    selectedRole,
    reFetch,
  ]);

  // on date change
  const handleCalendarChange = (selectedDate) => {
    // console.log(selectedDate);
    if (!selectedDate) {
      setDefaultDates("");
    } else if (selectedDate instanceof Array && selectedDate.length === 2) {
      const [startDate, endDate] = selectedDate;

      const adjustedEndDate = endOfDay(endDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, adjustedEndDate]);
    } else if (selectedDate instanceof Date) {
      const startDate = startOfDay(selectedDate);
      const endDate = endOfDay(selectedDate);

      setPagination({ pageIndex: 0, pageSize: 10 });
      setDefaultDates([startDate, endDate]);
    }
  };

  // table columns
  const columns = useMemo(
    () => [
      {
        accessorFn: (row) => `${row?.user?.first_name} ${row?.user?.last_name}`,
        id: "name",
        header: "NAME",
      },

      {
        accessorFn: (row) => row?.user?.phone,
        id: "phone",
        header: "PHONE",
      },
      {
        accessorFn: (row) => row?.company?.name,
        id: "company",
        header: "COMPANY",
      },
      {
        accessorFn: (row) => row?.branch?.name,
        id: "branch",
        header: "BRANCH",
      },
      {
        accessorFn: (row) => `${row?.role?.name}`,
        id: "role",
        header: "ROLE",
      },
      {
        accessorFn: (row) =>
          moment(row?.created_at).format("Do MMM YYYY - hh:mm A"),
        id: "date",
        header: "DATE ADDED",
      },
    ],
    []
  );

  //Addition to the final transcript is out. check your mails for attached
  //transcrits.
  //Best regards

  //doReset
  const doReset = () => {
    setReset(!reset);
    setActualSearchValue("");
    setSelectedBranch("");
    setDefaultDates(null);
  };

  //handleOnBranchChnage
  const handleOnBranchChnage = (data) => {
    // console.log(data);
    setSelectedBranch(data);
  };

  return (
    <>
      <div className="h-screen bg-gray-200 overflow-hidden">
        <div className="flex h-full">
          <CustomSideBar staffActive="active" />

          <div className="w-full">
            <CustomNavbar />

            <div className="p-5 h-full overflow-y-auto pb-24">
              <div className="flex items-center justify-between">
                <Typography variant="h3">Staff</Typography>
                <div className="flex gap-3">
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex items-center justify-center"
                    onClick={() => doReset()}
                  >
                    <ArrowPathIcon className="h-5 w-5" />
                  </Button>
                  <Button
                    variant="gradient"
                    color="red"
                    size="sm"
                    className="flex gap-2 items-center justify-center"
                    onClick={() => handleOpenAddModal()}
                  >
                    <PlusIcon className="h-5 w-5" />
                    Add
                  </Button>
                </div>
              </div>

              {/* search */}
              <div className="mt-3 p-5 rounded-[15px] shadow-md bg-white lg:flex lg:justify-between lg:items-center">
                <div className="lg:grid lg:grid-cols-4 gap-x-[20px]">
                  <div className="mb-2 lg:mb-0 w-full">
                    <BranchFilter
                      onBranchChange={(selected) =>
                        handleOnBranchChnage(selected)
                      }
                    />
                  </div>

                  <div className="w-full mb-2 lg:mb-0">
                    <RoleFilter
                      onRoleChange={(selected) => setSelectedRole(selected)}
                    />
                  </div>
                  <div className="w-full mb-2 lg:mb-0">
                    <DatePicker
                      defaultDates={defaultDates}
                      onDateChange={(e) => handleCalendarChange(e)}
                    />
                  </div>

                  <div className="w-full mb-2 lg:mb-0 relative">
                    <input
                      className="px-3 h-[35px] w-full border-[1px] border-gray-400 rounded-[5px] focus:outline-blue-500"
                      placeholder="Search staff by first name"
                      value={actualSearchValue}
                      onChange={(e) => setActualSearchValue(e.target.value)}
                    />
                    <MagnifyingGlassIcon className="h-5 w-5 absolute right-1 bottom-2" />
                  </div>
                </div>
              </div>

              <div className="lg:grid lg:grid-cols-3 gap-5 mt-3">
                <TotalStaffCard total={total} />
              </div>

              {/* table */}
              <div className="mt-5">
                <MaterialReactTable
                  enableColumnResizing
                  columns={columns}
                  data={companiesData}
                  enableRowSelection={false}
                  rowCount={total}
                  enableColumnActions={false}
                  enableDensityToggle={false}
                  enableFilters={false}
                  enableFullScreenToggle={false}
                  enableSorting={false}
                  manualPagination
                  onPaginationChange={setPagination}
                  state={{
                    pagination,
                    showProgressBars: isFetching,
                    density: "compact",
                    isLoading: isFetching,
                  }}
                  defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 120,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* open add staff modal */}
      {openAddModal ? (
        <AddStaffModal
          isOpen={openAddModal}
          handleOpen={handleOpenAddModal}
          handleClose={handleCloseAddModal}
          handleReFetch={handleReFetch}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default StaffPage;
