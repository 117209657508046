import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import axios from 'axios';
import { FUEL_BACKOFFICE_API } from '../../utils/helpers/constants';
import PhoneInput from 'react-phone-number-input';
import { removeBlankSpaces } from '../../utils/funtions';
import ShowToast from '../alerts/all_toasts';
import { saveCredentials } from '../../utils/helpers';
import { gloabal_variables } from '../../utils/helpers/globalVariables';

const SignInForm = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [pin, setPin] = useState('');
  const [isBusy, setIsBusy] = useState('');
  const [pinError, setPinError] = useState(false);

  //handle signin
  const handleSignIn = () => {
    if (phoneNumber.length <= 5) {
      setPhoneError(true);
    } else if (!pin) {
      setPinError(true);
    } else {
      setPhoneError(false);
      setPinError(false);
      setIsBusy(true);
      const authData = {
        phone: removeBlankSpaces(phoneNumber),
        pin: pin,
      };
      // console.log(authData);
      axios
        .post(
          `${FUEL_BACKOFFICE_API}/v1${gloabal_variables.sign_in_route}`,
          authData
        )
        .then((response) => {
          // console.log(response);
          if (response?.status === 200) {
            saveCredentials(
              response?.data?.payload?.token,
              response?.data?.payload
            );
            navigate('/dashboard/overview');
            setIsBusy(false);
          }
        })
        .catch((error) => {
          // console.log(error);
          ShowToast.error_toast(error?.response?.data?.message);
          setIsBusy(false);
        });
    }
  };

  return (
    <>
      <div className='h-screen overflow-hidden bg-gradient-to-b from-orange-500 via-orange-300 to-orange-200'>
        <div className='h-full flex flex-col justify-center items-center px-5'>
          <p className='mb-5 text-white font-semibold text-[32px] text-center'>
            Oya! Fuel Admin
          </p>

          <div className='h-auto w-full bg-white rounded-[20px] shadow-md px-5 py-10 text-black lg:w-[500px]'>
            <p className='text-[28px] font-medium'>Sign In</p>

            <div className='mt-5'>
              <label htmlFor='phone'>Phone Number</label>
              <PhoneInput
                id='phone'
                className='phone rounded-[5px] w-full border-[1px] border-gray-500 px-2 h-[40px]'
                defaultCountry='GH'
                inputStyle={{ width: '100%' }}
                value={phoneNumber}
                onChange={(phone) => setPhoneNumber(phone)}
                disabled={isBusy}
              />
              {phoneError && (
                <small className='text-red-500'>
                  Enter a valid phone number
                </small>
              )}
            </div>

            <div className='mt-5'>
              <label htmlFor='pin'>Enter your pin</label>
              <input
                className='h-[40px] rounded-[5px] w-full border-[1px] border-gray-500 px-2'
                id='pin'
                type='password'
                placeholder='****'
                value={pin}
                onChange={(e) => setPin(e.target.value)}
                disabled={isBusy}
              />
              {pinError && (
                <small className='text-red-500'>Enter your pin</small>
              )}
            </div>

            {/* <div className="mt-5 text-center text-red-500">
              <Link to="#">Forgot Password</Link>
            </div> */}

            <div className='mt-5'>
              {isBusy ? (
                <Loader />
              ) : (
                <button
                  className='h-[40px] w-full rounded-[30px] bg-orange-500 text-white'
                  onClick={() => handleSignIn()}
                >
                  Sign In
                </button>
              )}
            </div>

            {/* <div className="mt-5 flex gap-3 justify-center items-center">
              <p>Don't have an account?</p>
              <Link
                to="/auth/signup"
                className="text-blue-500 font-semibold underline"
              >
                Sign Up
              </Link>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInForm;
